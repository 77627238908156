import React from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import RowWrapper from '../utils/rowWrapper';
import Explore from '../utils/explore';
import {Link} from 'gatsby';
import { theme } from "../utils/theme"



const Container = styled.div`
  /* height: 100vh; */
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: visible;
`

const LandingContent = styled.div`
 height: 100vh;
 padding-top: 50px;

 @media ${props => props.theme.breaks.down('md')} {
    .title-cond {
      width: 90% !important;
    }
  }
`

const Title = styled.h1`
  font-size: 7rem;
  font-weight: 300;
  line-height: 1.1;
  text-align: center;
  margin-top: 15%;
  margin-bottom: 50px;
  color: ${props => props.theme.colors.body};

  &.bottom-h2 {
    font-size: 6rem;
    text-align: center;
    width: 600px;
    margin: 13% auto 20px auto;

    @media ${props => props.theme.breaks.down('md')} {
      font-size: 4rem;
      width: 90%;
      margin-top: 350px;
    }

    /* @media ${props => props.theme.breaks.down('sm')} {
      margin-top: 400px;
    } */
  }

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 5rem;
    margin-top: 20%;
    margin-bottom: 35px;

  }
`

const TitleCond = styled.h5`
  font-size: 3rem;
  font-weight: 300;
  width: 400px;
  line-height: 1.1;
  color: ${props => props.theme.colors.body};
  margin-bottom: 150px;
  text-align: left;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 2rem;
    margin-top: 50px;
    width: 90%;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    margin-bottom: 50px;
  }
`

const PainSection = styled.div`
  height: 100vh;
  padding-top: 50px;
  text-align: center;
  padding-left: 50px;
  position: relative;

  @media ${props => props.theme.breaks.down('md')} {
    .title-cond {
      width: 90% !important;
    }
    padding-left: 0;
    min-height: 180vh;
    height: auto;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    min-height: 1300px;
  }
`

const Pain = styled.div`
  position: absolute;
  left: ${props => props.l};
  top: ${props => props.t};
  height: ${props => props.s}px;
  width: ${props => props.s}px;
  border-radius: 50%;
  text-align: center;
  padding: ${props => props.p}px 5px;
  border: 1px solid ${props => props.theme.colors.body};
  color: ${props => props.theme.colors.body};
  z-index: 5;

  @media ${props => props.theme.breaks.down('md')} {
    left: ${props => props.lm};
    top: ${props => props.tm};
  }

  @media ${props => props.theme.breaks.down('sm')} {
    height: ${props => +props.s - 15}px;
    width: ${props => +props.s - 15}px;
    padding: ${props => +props.p - 10}px 5px;

    font-size: 1.5rem;
  }
`


const FixedFooter = styled.div`
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  position: fixed;
  /* left: 50%;
  transform: translateX(-50%); */
  height: 100px;
  padding-left: 40px;
  bottom: 20px;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
    bottom: 0;
  }
`


const PainPage = ({ data }) => {

  return (
    
      <Layout color={theme.colors.body} pageTitle="We like to listen.">
      <Container>
        <LandingContent className="sec-0">
        <div className="title-cond" style={{width: '70%', margin: '0 auto'}}>
          <TitleCond>Do you want to complain about what’s going on with your business?</TitleCond>
        </div>
            <Title>We like to listen.</Title>
            <Link to="/pain-form"><Explore sec="pain" sub="1" text="Connect" show bg={theme.colors.body} col={theme.colors.dark} /></Link>
        </LandingContent>
        
        <PainSection className="pain-sec">
          <div className="title-cond" style={{width: '70%', margin: '0 auto'}}>
          <TitleCond>Do you want to complain about what’s going on with your business?</TitleCond>
          </div>

          <Title className="bottom-h2">What’s your biggest problem?</Title>
          <Link to="/pain-form"><Explore sec="pain" sub="2" text="Connect" show bg={theme.colors.body} col={theme.colors.dark} /></Link>

          <Link to="/pain-form" state={{pain: "Not enough money"}}><Pain t="40%" l="10%" tm="70%" lm="60%" s="230" p="115">Not enough money</Pain></Link>
          <Link to="/pain-form" state={{pain: "Bad design"}}><Pain t="20%" l="65%" tm="350px" lm="15%" s="100" p="40">Bad design</Pain></Link>

          <Link to="/pain-form" state={{pain: "Something else"}}><Pain t="43%" l="75%" tm="170px" lm="50%" s="100" p="40">Something else</Pain></Link>

          <Link to="/pain-form" state={{pain: "Issues with my team"}}><Pain t="70%" l="35%" tm="680px" lm="65%" s="100" p="40">Issues with my team</Pain></Link>

          <Link to="/pain-form" state={{pain: "How does social media work?"}}><Pain t="65%" l="60%" tm="730px" lm="8%" s="230" p="115">How does social media work?</Pain></Link>

          <Link to="/pain-form" state={{pain: "Not enough customers"}}><Pain t="25%" l="25%" tm="1000px" lm="10%" s="160" p="60">Not enough customers</Pain></Link>

          <Link to="/pain-form" state={{pain: "Nobody understands what I do"}}><Pain t="10%" l="47%" tm="280px" lm="45%" s="160" p="60">Nobody <br />understands <br /> what I do</Pain></Link>

          <Link to="/pain-form" state={{pain: "Bad copy"}}><Pain t="15%" l="75%" tm="180px" lm="8%" s="160" p="70">Bad copy</Pain></Link>
          
        </PainSection>

         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>
        
      </Container>
      </Layout>
    
)
}

export default PainPage;